@import '../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
@import '../node_modules/bootstrap/dist//css/bootstrap.min.css';
@import '../node_modules/releox-react/lib/releox.css';

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #282c34;
  padding-top: 30px;
}

#root {
  background-color: inherit !important;
}
